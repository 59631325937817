import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import NavBar from "./components/NavBar";
import Home from "./views/Home";
import Support from "./views/Support";
import PrivacyPolicy from "./views/PrivacyPolicy";

const Router = () => {
  return (
    <BrowserRouter>
      <NavBar />
      <Switch>
        <Route path="/support">
          <Support />
        </Route>
        <Route path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
